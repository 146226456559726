import GitHubIcon from '@material-ui/icons/GitHub'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import { about } from '../../data'
import './Contact.css'


const Contact = () => {
  const {social } = about
return (
    <section className='section contact center' id='contact'>
      <h2 className='section__title'>Contact</h2>
      <h4 className='p-contact'>Check out my social media to keep in touch.</h4>
      <div>{social && (
          <>
            {social.github && (
              <a
                href={social.github}
                aria-label='github'
                className='link link--icon link-contact'
                target="_blank"
        rel="noreferrer"
              >
                <GitHubIcon />
              </a>
            )}

            {social.linkedin && (
              <a
                href={social.linkedin}
                aria-label='linkedin'
                className='link link--icon'
                target="_blank"
                rel="noreferrer"
              >
                <LinkedInIcon />
              </a>
            )}
          </>
        )}</div>
      
    </section>
  )
            }

export default Contact
