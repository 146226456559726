const header = {
  homepage: 'https://matejicmilan.com/',
  title: 'MM.',
}

const about = {
  name: 'Milan Matejic',
  role: 'Front End Developer',
  description: 'Open for Junior/Paid intern positions.',
  resume:
    'https://docs.google.com/document/d/17RyjwUELttQi05Ysc66SIvvLr33czPbd2S7Py9j06m8/edit?usp=sharing',
  social: {
    linkedin: 'https://www.linkedin.com/in/matejic83/',
    github: 'https://github.com/Mateja3m',
  },
}

const projects = [
  {
    name: 'Maja-Maki-Taxi',
    description:
      "First commercial project created for the local taxi company. Website is not in English.",
    stack: ['JavaScript, CSS, Bootstrap'],
    livePreview: 'https://maja-maki.com/',
  },
  {
    name: 'JavaScript Calculator',
    description:
      'This app is part of the curriculum of the FreeCodeCamp front end certification',
    stack: ['CSS', 'JavaScript', 'Responsive', 'Grid'],
    sourceCode: 'https://github.com/Mateja3m/js-calculator',
    livePreview: 'https://fcc-calc-app.netlify.app/',
  },
  {
    name: 'Drum Machine',
    description:
      "App build as part of the freeCodeCamp's curriculum using React.js, Bootstrap and grid layout",
    stack: ['React', 'Bootstrap', 'Grid'],
    sourceCode: 'https://github.com/Mateja3m/fcc-drum-machine',
    livePreview: 'https://fcc-drum-machine-app.netlify.app/',
  },
  {
    name: 'Address Book App',
    description:
      'App build using React.js, with functional components, useState hook, forms',
    stack: ['React'],
    sourceCode: 'https://github.com/Mateja3m/react-address-book',
    livePreview: 'https://address-colection-app.netlify.app/',
  },
  {
    name: 'Numbers Converter',
    description:
      'The app converts roman to arabic numbers and changes its background.',
    stack: ['CSS', 'JavaScript', 'Responsive'],
    sourceCode: 'https://github.com/Mateja3m/romanNumbersConverter',
    livePreview: 'https://roman-number-converter.netlify.app/',
  },
  {
    name: 'Markdown Previewer',
    description:
      'Markdown Previewer is created using React.js, with help of Bootstrap and CSS',
    stack: ['CSS', 'React', 'Bootstrap'],
    sourceCode: 'https://github.com/Mateja3m/fcc-markdown-previewer',
    livePreview: 'https://app-markdown-preview.netlify.app/',
  },
]

const skills = [
  'HTML',
  'CSS',
  'JavaScript',
  'React',
  'React context',
  'React router',
  'SASS',
  'Bootstrap',
  'React bootstrap',
  'Git',
]



export { header, about, projects, skills }
